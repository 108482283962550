import React, { useEffect, useState } from "react"
import { getLanguages, translate } from "../../../../selectors/translations"
import useFormStateHandlerG2, { FormValidators } from "../../../../hooks/formStateHandlerG2"
import { useSelector } from "react-redux"
import { authUser } from "../../../../selectors/auth"
import './PatientCommonForm.scss'
import { ReactComponent as UserIcon } from '../../../../assets/img/icons/user-icon.svg'
import { PatientFormMode } from "../../constants/constants"
import PhoneInputWithMask from "../../../../components/PhoneInputWithMask"
import { getInstitutionsSelector, institutionName } from "../../../../selectors/institutions"
import { isEqual } from "../../../../utils/isEqual"
import { MedStaffRole } from "../../../../sdk"
import useTranslate from "../../../../hooks/useTranslate";

const VALIDATORS: FormValidators = {
  firstName: [
    (value) => value ? null : 'firstName'
  ],
  lastName: [
    (value) => value ? null : 'lastName'
  ],
  institution: [
    (value) => value ? null : 'institution'
  ],
  personalNumber: [
    (value, inputs) => {
      const patientInstitution = institutionName(inputs?.institution);
      if (!value) {
        return 'invalidPNRLength';
      }
      const normalizedPNR = value ? value.toString().replace(/-/g, '').trim() : '';
      if (!/^[0-9]+$/.test(normalizedPNR)) {
        return "invalidPNRFormat";
      }
      const validLength = patientInstitution === "DSS" ? 10 : 12;
      if (normalizedPNR.length !== validLength) {
        return 'invalidPNRLength';
      }
      return null;
    },
  ],
};

interface IProps {
  data: { [key: string]: any };
  mode: PatientFormMode;
  onFieldChange?: (data: any) => void;
  children?: React.ReactNode;
}

const PatientCommonForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {data, onFieldChange, children} = props;
  const [previousData, setPreviousData] = useState(data);
  const languages = useSelector(getLanguages);
  const user = useSelector(authUser);
  const translateLocalazy = useTranslate()

  const {
    inputs,
    errors,
    handleInputChange,
    handleInputFocus,
    handleInputBlur,
    handleForceUpdate,
    setValueDirectly
  } = useFormStateHandlerG2(null, data, VALIDATORS, onFieldChange);

  useEffect(() => {
    if (isEqual(data, previousData)) {
      return;
    }
    handleForceUpdate(data, false);
    setPreviousData(data);
  }, [data]);

  useEffect(() => {
    const patientInstitution = institutionName(inputs.institution ?? user.institution)
    if (patientInstitution === 'DSS') {
      handleForceUpdate({language: 'da'})
      handleForceUpdate({danishPatient: true})
    }
    if (patientInstitution !== 'DSS') {
      handleForceUpdate({danishPatient: false})
    }
  }, [inputs.institution])
  const institutions = useSelector(getInstitutionsSelector);

  const DSSInstitution = institutionName(inputs.institution ?? user.institution) == "DSS"

  return (
    <div className="card-box patient-info">
      <div className="patient-common-form__header">
        <div className="box-title">
          <i className="icon-holder patients-title_icon-holder"><UserIcon color="#ffffff"/></i>
          <h3>{translate('headerCreatePatientSteps.step1')}</h3>
        </div>
      </div>
      <div className="card_inputs-box" style={{marginBottom: 0}}>
        <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('firstName')}*</label>
          <input type="text"
                 onChange={handleInputChange}
                 onFocus={handleInputFocus}
                 value={inputs.firstName}
                 name="firstName"
                 placeholder={translate('Type here...')}/>
          {errors.includes('firstName') &&
            <span className="login_form-error">{translate('Field must not be empty')}</span>}
        </div>
        <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('lastName')}*</label>
          <input type="text"
                 onChange={handleInputChange}
                 onFocus={handleInputFocus}
                 value={inputs.lastName}
                 name="lastName"
                 placeholder={translate('Type here...')}/>
          {errors.includes('lastName') &&
            <span className="login_form-error">{translate('Field must not be empty')}</span>}
        </div>
        <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('personalNumber')}*</label>
          <input type="text"
                 onChange={handleInputChange}
                 onBlur={handleInputBlur}
                 value={inputs.personalNumber}
                 name="personalNumber"
                 placeholder={DSSInstitution ? translateLocalazy('form.placeholder.pnr-da') : translateLocalazy('form.placeholder.pnr')}/>
          {errors.includes('invalidPNRFormat') && <span
            className="login_form-error">{translateLocalazy('form-validation.errors.pnr.format')}</span>}
          {errors.includes('invalidPNRLength') && <span
            className="login_form-error">{DSSInstitution ? translateLocalazy('form-validation.errors.pnr.length-10') : translateLocalazy('form-validation.errors.pnr.length')}</span>}
        </div>
        <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('phone')}</label>
          <PhoneInputWithMask
            inputValue={inputs.phoneNumber || ''}
            institution={inputs.institution ?? user.institution}
            onChange={(value: string) => setValueDirectly('phoneNumber', value)}
          />
        </div>
        <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('l')}</label>
          <select onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  value={inputs.language}
                  name="language">
            {languages.map((l: { key: string }, i: number) => (
              <option key={i} value={l.key}>{translate(l.key)}</option>))}
          </select>
        </div>
        {user.roles.includes(MedStaffRole.ADMIN) &&
          <div className="input-holder flex-item item-sm-6 item-md-4">
            <label>{translate('institution')}</label>
            <select onChange={handleInputChange}
                    value={inputs.institution}
                    style={{width: '100%'}}
                    name="institution">
              {institutions.map((i) => <option key={i.id}
                                               value={i.id}>{i.name}</option>)}
            </select>
          </div>
        }

      </div>
      {children ?
        <div>
          {children}
        </div>
        : null
      }
    </div>
  );
};

export default PatientCommonForm;
