import { all, put, takeLatest } from 'redux-saga/effects'
import { TranslationsActionTypes } from './types'
import { fetchTranslationsDone, fetchTranslationsSuccess } from "./actions"
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"
import { TranslationApp } from "../../sdk"

function* handleFetchTranslations(): Generator {
  const apiCall = apiV2.translations.getBatch.bind(apiV2.translations)
  const res = (yield wrapResponse(apiCall([
    TranslationApp.GERAS_COMMON,
    TranslationApp.GERAS_HCP,
    TranslationApp.GERAS_MOBILE_APP,
    TranslationApp.GERAS_SYMPTOM_SURVEY,
  ]))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(fetchTranslationsSuccess(res.data))
  }

  yield put(fetchTranslationsDone())
}

function* handleForceRefreshTranslations(): Generator {
  const apiCall = apiV2.translations.forceRefresh.bind(apiV2.translations)
  yield wrapResponse(apiCall())
  yield handleFetchTranslations()
}

function* translationsSaga(): Generator {
  yield all([
    takeLatest(TranslationsActionTypes.FETCH_TRANSLATIONS, handleFetchTranslations),
    takeLatest(TranslationsActionTypes.FORCE_REFRESH_TRANSLATIONS, handleForceRefreshTranslations),
  ])
}

export default translationsSaga
