import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { authUser } from "../../../selectors/auth"
import { languageInUse, translate } from "../../../selectors/translations"
import {
  createPatientRequest,
  createPatientReset,
  getAnamnesisConfig,
  unsetPatientProfile
} from "../../../store/PatientProfile/action"
import { getPatientState, getQuestioners } from "../../../selectors/patientProfile"
import { openModalWindow } from "../../../store/ModalWindowData/action"
import { Redirect } from "react-router"
import PatientAnamnesisForm from "../components/PatientAnamnesisForm"
import './PatientCreatePage.scss'
import { PatientFormMode } from "../constants/constants"
import PatientCommonForm from "../components/PatientCommonForm"
import { RouteNames } from "../../../types/constants"
import { AppLanguage, AvailableQuestionersDto, MedStaffRole, QuestionerType } from "../../../sdk"

const PatientCreatePage: React.FC = (): JSX.Element => {

  const [commonFormState, setCommonFormState] = useState<any>({})
  const anamnesisSections = useSelector(getQuestioners)
    .find(q => q.kind == QuestionerType.ANAMNESIS)?.sections
    .map(e => e.slug) || []
  const [availableQuestioners, setAvailableQuestioners] = useState<AvailableQuestionersDto>({
    [QuestionerType.SYMPTOM_SURVEY]: [],
    [QuestionerType.COGNITIVE]: [],
    [QuestionerType.ANAMNESIS]: anamnesisSections
  })

  const dispatch = useDispatch()
  const user = useSelector(authUser)
  const currentLanguage = useSelector(languageInUse) as string
  const patientProfile = useSelector(getPatientState)

  const handleSubmit = useCallback(() => {
    const {inputs, isFormValid} = commonFormState
    if (!isFormValid) {
      return
    }
    if (inputs.personalNumber) {
      const reG = /-/gi
      inputs.personalNumber = inputs.personalNumber.replace(reG, '')
    }

    const payload = {
      ...inputs,
      availableQuestioners
    }
    if (user.roles.includes(MedStaffRole.ADMIN)) {
      dispatch(createPatientRequest(payload))
    } else {
      dispatch(openModalWindow('createPatientConfirmation', payload))
    }
  }, [commonFormState, availableQuestioners])

  useEffect(() => {
    dispatch(getAnamnesisConfig('', currentLanguage))
  }, [currentLanguage])

  useEffect(() => {
    return () => {
      dispatch(unsetPatientProfile())
    }
  }, [])

  if (patientProfile.redirectAfterCreation) {
    dispatch(createPatientReset())
    return (
      <Redirect to={RouteNames.Patient}/>
    )
  }

  return (
    <div className="patient-create-page__box">
      <h1 className="patient-create-page__heading">{translate('headerCreatePatient')}</h1>
      <PatientCommonForm
        data={{
          danishPatient: false,
          email: '',
          firstName: '',
          username: '',
          phoneNumber: '',
          institution: user.institution,
          language: AppLanguage.SV,
          lastName: '',
          personalNumber: '',
        }}
        onFieldChange={setCommonFormState}
        mode={PatientFormMode.CreateNewPatient}>
      </PatientCommonForm>
      <PatientAnamnesisForm
        homeScreenMap={availableQuestioners}
        patientInstitution={commonFormState.inputs?.institution ?? user.institution}
        onFormChange={setAvailableQuestioners}>
        <div className="btns-holder">
          {!commonFormState.isFormValid &&
              <button className="t_uppercase g_btn g_btn--disabled"
                      type="button">{translate('createNewPatient')}</button>
          }
          {commonFormState.isFormValid &&
              <button onClick={handleSubmit}
                      className="t_uppercase g_btn g_btn--primary"
                      type="button">{translate('createNewPatient')}</button>
          }
        </div>
      </PatientAnamnesisForm>
    </div>
  )
}

export default PatientCreatePage
