import React, {useCallback} from "react";
import classNames from "classnames";
import './MedicalTestItem.scss';

interface IProps {
    id: string | number;
    isSelected: boolean;
    title: string;
    onClick: (id: any) => void;
}

export const MedicalTestItem: React.FC<IProps> = (props: IProps): JSX.Element => {
    const {id, isSelected, title, onClick} = props;

    const handleClick = useCallback(() => {
        onClick && onClick(id);
    }, [onClick, id]);

    const className = classNames([
        'medical-test-item',
        {
            'medical-test-item--selected': isSelected
        }
    ]);

    return (
        <div className="medical-test-item__wrapper">
            <div className={className}
                 onClick={handleClick}>{title}</div>
        </div>
    );
};

export default MedicalTestItem;
