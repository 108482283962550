import React, {useState, SyntheticEvent, useEffect} from 'react';
import { translate } from '../../selectors/translations';
import { ModalWindowAction } from '../../store/ModalWindowData/types';
import ProgressBar from '../ProgressBar/ProgressBar';
import { ReactComponent as BankIdLogo } from '../../assets/img/icons/bankId-logo.svg';
import {useSelector} from "react-redux";
import { getDataForPDFReport } from "../../selectors/patientProfile"
import {ReactComponent as CheckboxIcon} from "../../assets/img/icons/check-mark.svg";
import CheckboxItem from "../CheckboxItem";
import {PdfReportSectionData, PdfReportSubsectionData} from "../../models/pdfReportData";
import {PdfItems} from "../../types/constants";
import {Link} from "react-router-dom";
import { defineIsSelectedAll, itemsWithSubsections, mapPdfSelectionToDto } from "../../utils/mapPdfSelectionToDto"
import useTranslate from "../../hooks/useTranslate"
import { CreatePatientPayload } from "../../store/PatientProfile/types"

type PropsFromState = {
  onConfirm: (data: ModalWindowAction<any>) => void;
  data: any;
}

export const RemoveConfirmationPopup = (props: PropsFromState) => {
  const { data, onConfirm } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Are you sure you want to archive this user?')} <br /> <strong>{data.firstName} {data.lastName}</strong></p>
          <p>{translate('You can restore this user later')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('cancel')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--confirm"
            type="button"
            onClick={(): void => (onConfirm({ action: data.type, data: data._id }))}>{translate('Archive user')}</button>
        </div>
      </div>
    </div>
  )
}

export const DeleteConfirmationPopup = (props: PropsFromState) => {
  const { data, onConfirm } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Are you sure you want to completely delete this user?')} <br /> <strong>{data.firstName} {data.lastName}</strong></p>
          <p>{translate('All data related to this user will be erased. This action can be undone.')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('cancel')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--confirm"
            type="button"
            onClick={(): void => (onConfirm({ action: data.type, data: data._id }))}>{translate('Delete user')}</button>
        </div>
      </div>
    </div>
  )
}

export const RestoreConfirmationPopup = (props: PropsFromState) => {
  const { data, onConfirm } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Are you sure you want to restore this user?')} <br /> <strong>{data.firstName} {data.lastName}</strong></p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('cancel')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--confirm"
            type="button"
            onClick={(): void => (onConfirm({ action: data.type, data: data._id }))}>{translate('Restore user')}</button>
        </div>
      </div>
    </div>
  )
}

export const DownloadPdfPopup = () => {
  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Generating PDF file process starting...')}</p>
          <p><strong>{translate('We strongly recommend do not reload the page.')}</strong></p>
        </div>
        <div style={{ position: 'relative' }}>
          <ProgressBar />
        </div>
      </div>
    </div>
  )
}

export const DownloadFilePopup = (props: PropsFromState) => {
  const { onConfirm } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('File was generated. Please click button below to download it')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'downloadFile', data: null })}>{translate('Download file')}</button>
        </div>
      </div>
    </div>
  )
}

export const BankIdLoginPopup = () => {
  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <BankIdLogo style={{ height: '100px', width: '100px' }} />
          <p><strong>{translate('modal.bankId.title')}</strong></p>
          <p>{translate('modal.bankId.description')}</p>
        </div>
        <div style={{ position: 'relative' }}>
          <ProgressBar />
        </div>
      </div>
    </div>
  )
}

type ConfirmationConsentProps = {
  data: CreatePatientPayload,
  onCancel: (value: boolean) => void;
  onConfirm: (data: ModalWindowAction<any>) => void;
}

const ConfirmationConsentFirst: React.FC<ConfirmationConsentProps> = (props: ConfirmationConsentProps) => {
  const { onCancel, onConfirm, data } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Have you obtained verbal consent from the patient to create an account on their behalf?')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onCancel(false)}>{translate('No')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--yes"
            type="button"
            onClick={(): void => onConfirm({ action: 'createPatient', data })}>{translate('Yes')}</button>
        </div>
      </div>
    </div>
  )
}

type ConfirmationConsentSProps = {
  onConfirm: (data: ModalWindowAction<any>) => void;
  data: CreatePatientPayload,
}

const ConfirmationConsentSecond: React.FC<ConfirmationConsentSProps> = (props: ConfirmationConsentSProps) => {
  const { onConfirm, data } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Please obtain verbal consent from the patient to create an account on their behalf.')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('cancel')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--yes"
            type="button"
            onClick={(): void => onConfirm({ action: 'createPatient', data })}>{translate('Consent obtained')}</button>
        </div>
      </div>
    </div>
  )
}

export const PatientConsentConfirmation = (props: PropsFromState) => {
  const [isConsent, setConsent] = useState(true);
  const { onConfirm, data } = props;

  return isConsent
    ? <ConfirmationConsentFirst onCancel={setConsent} onConfirm={onConfirm} data={data}/>
    : <ConfirmationConsentSecond onConfirm={onConfirm} data={data}/>
}

export const IeWarningPopup = (props: PropsFromState) => {
  const { onConfirm } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('ieWarning')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--yes"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('Ok')}</button>
        </div>
      </div>
    </div>
  )
}

export const ConfirmationConsentInstitution = (props: PropsFromState) => {
  const { onConfirm, data } = props;

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translate('Do you have permission from patient to share patient data with other institution?')}</p>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--yes"
            type="button"
            onClick={(): void => onConfirm({ action: 'addInstitution', data })}>{translate('Yes')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('No')}</button>
        </div>
      </div>
    </div>
  )
}

export const AppVersionPopup = (props: PropsFromState) => {
  const { onConfirm, data } = props;
  const [appVersion, setAppVersion] = useState(data.version);
  const translateLocalazy = useTranslate()

  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
        <div className="confirmation_message">
          <p>{translateLocalazy('versioning.modal.title')}</p>
        </div>
        <div className="card-box no-borders">
          <div className="card-input-box">
            <div style={{marginBottom: '9px'}}
                 className="input-holder flex-item"><label>{translateLocalazy('versioning.hcp')}</label>
              <input
                value={appVersion.hcpPortal}
                onChange={(e: SyntheticEvent): void => {
                  e.persist()
                  setAppVersion({...appVersion, ...{'hcpPortal': (e.target as HTMLInputElement).value}})
                }}
                type="text"
                name="hcpPortal" placeholder={translate('Type here...')}/>
            </div>
            <div className="input-holder flex-item" style={{marginBottom: '9px'}}>
              <label>{translateLocalazy('versioning.pp')}</label>
              <input
                value={appVersion.patientPortal}
                onChange={(e: SyntheticEvent): void => {
                  e.persist()
                  setAppVersion({...appVersion, ...{'patientPortal': (e.target as HTMLInputElement).value}})
                }}
                type="text"
                name="pp" placeholder={translate('Type here...')}/>
            </div>
            <div style={{marginBottom: '9px'}}
                 className="input-holder flex-item"><label>{translateLocalazy('versioning.ios')}</label>
              <input
                value={appVersion.ios}
                onChange={(e: SyntheticEvent): void => {
                  e.persist()
                  setAppVersion({...appVersion, ...{'ios': (e.target as HTMLInputElement).value}})
                }}
                type="text"
                name="iOS" placeholder={translate('Type here...')}/>
            </div>
            <div style={{marginBottom: '9px'}}
                 className="input-holder flex-item"><label>{translateLocalazy('versioning.android')}</label>
              <input
                value={appVersion.android}
                onChange={(e: SyntheticEvent): void => {
                  e.persist()
                  setAppVersion({...appVersion, ...{'android': (e.target as HTMLInputElement).value}})
                }}
                type="text"
                name="android" placeholder={translate('Type here...')}/>
            </div>
            <div className="input-holder flex-item"><label>{translateLocalazy('versioning.ss')}</label>
              <input
                value={appVersion.symptomSurvey}
                onChange={(e: SyntheticEvent): void => {
                  e.persist()
                  setAppVersion({...appVersion, ...{'symptomSurvey': (e.target as HTMLInputElement).value}})
                }}
                type="text"
                name="iOS" placeholder={translate('Type here...')}/>
            </div>
          </div>
        </div>
        <div className="confirmation_btns-box">
          <button
            className="confirmation_btn g_btn confirmation_btn--yes"
            type="button"
            onClick={(): void => onConfirm({ action: 'setAppVersion', data: appVersion })}>{translate('save')}</button>
          <button
            className="confirmation_btn g_btn confirmation_btn--cancel"
            type="button"
            onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('cancel')}</button>
        </div>
      </div>
    </div>
  )
};

export const RefreshLoginCodePopup = (props: PropsFromState) => {
  const {onConfirm, data } = props;
  return (
      <div className="confirmation_wapper">
        <div className="confirmation_box">
          <div className="confirmation_message">
            <p>{translate('modal.confirmation.refreshLoginCode')}</p>
          </div>
          <div className="confirmation_btns-box">
            <button
                className="confirmation_btn g_btn confirmation_btn--yes"
                type="button"
                onClick={(): void => onConfirm({ action: 'refreshLoginCode', data })}>{translate('Yes')}</button>
            <button
                className="confirmation_btn g_btn confirmation_btn--cancel"
                type="button"
                onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('No')}</button>
          </div>
        </div>
      </div>
  )
};

export const ItemsForPDFReportPopup = (props: PropsFromState) => {
  const { onConfirm, data } = props;
  const initialItemsSet: any = useSelector(getDataForPDFReport);
  const [ itemsSet, setItemsSet ] = useState(initialItemsSet);
  const [ areCheckboxesSelected, setAreCheckboxesSelected ] = useState(true);

  const handleSelectAll = () => {
    setItemsSet((prevState: any) => {
      if (areCheckboxesSelected) {
        setAreCheckboxesSelected(false);
        return Object.keys(prevState).reduce((prev: any, key) => {
          // @ts-ignore
          const item = prevState[key];
          if (Array.isArray(item)) {
            prev[key] = item.map(item => {
              if (itemsWithSubsections.includes(item.type)) {
                item.deselectAllSubsections();
              }
              item.deselect();
              return item;
            });
          } else {
            item.deselect();
            prev[key] = item;
          }
          return prev;
        }, {});
      }
      setAreCheckboxesSelected(true);
      return Object.keys(prevState).reduce((prev: any, key) => {
        // @ts-ignore
        const item = prevState[key];
        if (Array.isArray(item)) {
          prev[key] = item.map(item => {
            if (itemsWithSubsections.includes(item.type)) {
              item.selectAllSubsections();
            }
            item.select();
            return item;
          });
        } else {
          item.select();
          prev[key] = item;
        }
        return prev;
      }, {});
    });
  };

  const changeSelection = (itemMetaData: any) => {
    // setAreCheckboxesSelected(!areCheckboxesSelected);
    if (itemMetaData.type === PdfItems.SubSections) {
      setItemsSet((prevState: any) => {
        const parentSection = prevState[itemMetaData.parentType].find((item: any) => {
          return item.id === itemMetaData.parentId;
        }) as PdfReportSectionData;
        if (!parentSection) {
          return {
            ...prevState,
          };
        }
        const newItem = prevState[itemMetaData.parentType].map((item: any) => {
          if (item.id === itemMetaData.parentId) {
            item.toggleSubsection(itemMetaData.id);
            item.refreshSelfSelection();
          }
          return item;
        });
        return {
          ...prevState,
          [itemMetaData.parentType]: newItem
        }
      });
      return;
    }
    if (
        [
          PdfItems.Status,
          PdfItems.Diagnosis,
          PdfItems.Summary,
          PdfItems.Recommendation,
          PdfItems.EditHistory,
          PdfItems.ViewHistory,
          PdfItems.Downloads
        ].includes(itemMetaData.type)
    ) {
      setItemsSet((prevState: any) => {
        // @ts-ignore
        const newItem = prevState[itemMetaData.type];
        newItem.toggleSelection();
        return {
          ...prevState,
          [itemMetaData.type]: newItem
        }
      });
      return;
    }
    setItemsSet((prevState: any) => {
      // @ts-ignore
      const newItem = prevState[itemMetaData.type].map(item => {
        if (item.id === itemMetaData.id) {
          item.toggleSelection();
        }
        return item;
      });
      return {
        ...prevState,
        [itemMetaData.type]: newItem
      }
    });
  };

  useEffect(() => {
    setAreCheckboxesSelected(defineIsSelectedAll(itemsSet));
  }, [itemsSet]);

  const handleSuccessClick = () => {
    onConfirm({ action: 'setPdfReportType', data: 'doctor' });
  };

  const handleSavePdfClick = () => {
    onConfirm({ action: 'setPdfReportType', data: 'patient' });
  };

  const handleCancelClick = () => {
    onConfirm({ action: 'cancel', data: null });
    // window.removeEventListener('storage', data.storageListener);
  };

  return (
      <>
          <div className="pb-20 t_center">
            <h2 className="m-0">{translate('download_pdf_report')}</h2>
          </div>

          <div className="popup-pdf-content-holder ph-10 pb-20">
            <div className="flex align-center margin-me--btm pb-5">
              <div className="custom-checkbox_wrapper margin-right">
                <input
                    checked={areCheckboxesSelected}
                    onChange={handleSelectAll}
                    className="hidden-checkbox"
                    type="checkbox"/>
                <div className="pseudo-checkbox_holder"></div>
                <CheckboxIcon className="pseudo-checkbox_icon"/>
              </div>
              <span
                  style={{cursor: 'pointer'}}
                  onClick={handleSelectAll}>
                  { translate('patientAccount.newPatient.patientTasks.selectAll') }
              </span>
            </div>
            <CheckboxItem data={itemsSet[PdfItems.Status]} onChangeCb={changeSelection}></CheckboxItem>
            { itemsSet[PdfItems.Diagnosis] &&
              <CheckboxItem data={itemsSet[PdfItems.Diagnosis]} onChangeCb={changeSelection}></CheckboxItem>
            }
            { itemsSet[PdfItems.Summary] &&
              <CheckboxItem data={itemsSet[PdfItems.Summary]} onChangeCb={changeSelection}></CheckboxItem>
            }
            { itemsSet[PdfItems.Recommendation] &&
              <CheckboxItem data={itemsSet[PdfItems.Recommendation]} onChangeCb={changeSelection}></CheckboxItem>
            }
            {
              itemsSet[PdfItems.AnamnesisNew].map((anamnesis: PdfReportSectionData) => {
                const subsections = anamnesis.subsections.map((subsection: PdfReportSubsectionData) => {
                  return <CheckboxItem key={subsection.id} data={subsection} onChangeCb={changeSelection}></CheckboxItem>
                });
                return (
                    <div key={anamnesis.id}>
                      <CheckboxItem data={anamnesis} onChangeCb={changeSelection}></CheckboxItem>
                      { !anamnesis.areSubsectionsClosed &&
                          <div className="pl-40">
                            {subsections}
                          </div>
                      }
                    </div>
                )
              })
            }
            {itemsSet[PdfItems.Cognitive].map((cognitive: PdfReportSectionData) => {
              return (
                <div key={cognitive.id}>
                  <CheckboxItem key={cognitive.id} data={cognitive}
                                onChangeCb={changeSelection}></CheckboxItem>
                  <div className="pl-40">
                    {cognitive.subsections.map((subsection: PdfReportSubsectionData) => {
                      return <CheckboxItem key={subsection.id} data={subsection}
                                           onChangeCb={changeSelection}></CheckboxItem>
                    })}
                  </div>
                </div>
              )
            })
            }
            {
              itemsSet[PdfItems.SymptomSurveys].map((survey: PdfReportSectionData) => {
                const subsections = survey.subsections.map((subsection: PdfReportSubsectionData) => {
                  return <CheckboxItem key={subsection.id} data={subsection} onChangeCb={changeSelection}></CheckboxItem>
                });
                return (
                    <div key={survey.id}>
                      <CheckboxItem data={survey} onChangeCb={changeSelection}></CheckboxItem>
                      { !survey.areSubsectionsClosed &&
                          <div className="pl-40">
                            {subsections}
                          </div>
                      }
                    </div>
                )
              })
            }
          </div>
          <div className="confirmation_btns-box pt-20">
              <div className="pr-20">
                <Link
                    to={{
                      pathname: `/generate-pdf/${data.userId}`,
                      search: `itemsSet=${JSON.stringify(mapPdfSelectionToDto(itemsSet))}&reportType=patient`
                    }}
                    target='_blank'>
                  <button
                      onClick={handleSavePdfClick}
                      className="confirmation_btn g_btn confirmation_btn--yes"
                      type="button">{translate('sendToPatientModalBtn')}
                  </button>
                </Link>
              </div>
            <Link
                to={{
                  pathname: `/generate-pdf/${data.userId}`,
                  search: `itemsSet=${JSON.stringify(mapPdfSelectionToDto(itemsSet))}&reportType=doctor`
                }}
                target='_blank'>
              <button
                  onClick={handleSuccessClick}
                  className="confirmation_btn g_btn confirmation_btn--yes"
                  type="button">{translate('downloadReportModalBtn')}</button>
            </Link>
            <div className="pl-20">
              <button
                  className="confirmation_btn g_btn confirmation_btn--cancel"
                  type="button"
                  onClick={handleCancelClick}>{translate('cancel')}</button>
            </div>
          </div>
      </>
  );
}
