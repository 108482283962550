import { TranlsationsState, TranslationsActionType, TranslationsActionTypes, TranlsationLanguage } from './types'

const initialState: TranlsationsState = {
  languages: [],
  translationsFetched: false
}

export function TranslationReducer(
  state = initialState,
  action: TranslationsActionType
): TranlsationsState {
  switch (action.type) {
    case TranslationsActionTypes.FETCH_TRANSLATIONS:
      return {
        ...state,
        translationsFetched: false,
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_SUCCESS:
      let newTranslations = state.languages
      for (const [, trans] of Object.entries(action.payload)) {
        // @ts-ignore
        for (const [language, value] of Object.entries(trans)) {
          newTranslations = newTranslations.map(
            (item) => item.key === language ? {
                ...item,
                translations: Object.assign({}, item.translations, value)
              }
              : item
          )
        }
      }

      return {
        ...state,
        translationsFetched: true,
        languages: newTranslations
      }
    case TranslationsActionTypes.FETCH_TRANSLATIONS_DONE:
      return {
        ...state,
        translationsFetched: true,
      }
    case TranslationsActionTypes.SWITCH_TRANSLATION:
      return {
        ...state,
        languages: state.languages.map((l: TranlsationLanguage) => ({...l, inUse: l.key === action.language}))
      }
    default:
      return state
  }
}
